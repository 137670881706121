<template>
    <div v-if="isViewNotifications">
        <b-card no-body>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"></b-col>
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" @input="fetchNotificationTypes"
                                class="d-inline-block mr-1" placeholder="Search..." />
                            <AddNotificationType :manage="isAddNotificationTypes" :token="bearerToken"
                                @fetchNotificationTypes="fetchNotificationTypes" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <NotificationType :show="show" :notificationType="notification_type.data || []" :manage="isAddNotificationTypes" :token="bearerToken"
                @fetchNotificationTypes="fetchNotificationTypes" />
            <div class="mx-2 mt-1 mb-1">
                <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                    <div class="dataTables_info mt-1"></div>
                    <pagination class="mt-1 mb-0" :data="notification_type" :limit="2"
                        @pagination-change-page="fetchNotificationTypes" />
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        NotificationType: () => import("@/views/components/notifications/NotificationType.vue"),
        AddNotificationType: () => import("@/views/components/notifications/AddNotificationType.vue"),
        ToastificationContent,
        BCard,
        BRow,
        BCol,
        BFormInput,
    },
    data() {
        return {
            isAddNotificationTypes: false,
            searchQuery: "",
            show: false,
            alertMessageToast: "",
            bearerToken: "Bearer " + localStorage.getItem("_t"),
            notification_type: [],
            isViewNotifications: false,
        };
    },
    mounted() {
        const permissions = JSON.parse(localStorage.getItem("permission")).permission;
        this.isAddNotificationTypes = permissions.includes("add_app_notifications_type");
        this.isViewNotifications = permissions.includes("view_app_notifications_type");

        this.fetchNotificationTypes();
    },
    methods: {
        async fetchNotificationTypes(page = 1) {
            this.show = true;

            try {
                const res = await axios.post(
                    `${process.env.VUE_APP_API_URL}/notification-types?page=${page}`,
                    { search: this.searchQuery },
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.notification_type = res.data.notification_types;
            } catch (error) {
                console.error("Error fetching notification types:", error);
            } finally {
                this.show = false;
            }
        },
    },
};
</script>
